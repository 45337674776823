import React from 'react';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import moment from 'moment';

const NotificationText = ({ notificationObject: { actorStr, verbStr, targetStr, timestamp } }) => (
  <Grid item container direction="row">
    <Grid item>
      <ListItemText
        primary={actorStr}
        primaryTypographyProps={{ style: { fontWeight: 400 } }}
        secondary={actorStr && moment(timestamp).format('DD/MM/YYYY')}
      />
    </Grid>
    <Grid item>
      <ListItemText
        primary={verbStr}
        style={{ whiteSpace: 'pre' }}
        primaryTypographyProps={{ style: { fontWeight: 200, marginTop: 6 } }}
        secondary={actorStr ? '' : moment(timestamp).format('DD/MM/YYYY')}
      />
    </Grid>
    <Grid item>
      <ListItemText
        primary={targetStr}
        primaryTypographyProps={{ style: { fontWeight: 400, marginTop: 6 } }}
      />
    </Grid>
  </Grid>
);

NotificationText.propTypes = {
  notificationObject: PropTypes.shape({
    actorStr: PropTypes.string.isRequired,
    verbStr: PropTypes.string.isRequired,
    targetStr: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotificationText;
