import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  logIn,
  setSelectedCompanyRutFromExternalLogin,
} from '@fingo/lib/apollo/reactive-variables/localUpdates';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ACEPTA_LOGIN_SESSION_ID } from '../graphql/mutations';

const AceptaClickController = () => {
  const location = useLocation();
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [urlParams] = useState(new URLSearchParams(location.search));
  const [loginAcepta, { loading }] = useMutation(ACEPTA_LOGIN_SESSION_ID, {
    variables: {
      buttonFlag: urlParams.get('button'),
      sessionId: urlParams.get('session_id'),
      clientRut: parseInt(urlParams.get('client_rut'), 10),
      companyRut: parseInt(urlParams.get('company_rut'), 10),
    },
    onCompleted: (_data) => {
      const { aceptaAuthSessionId: response } = _data;
      logIn(response);
      setSelectedCompanyRutFromExternalLogin(response);
      history.push(
        { pathname: '/app' },
        { partner: 'acepta' },
      );
    },
    onError: () => setShowError(true),
  });
  useEffect(() => {
    loginAcepta();
  }, [loginAcepta, urlParams]);
  return (
    <Stack height="100vh" width="100%" alignItems="center" justifyContent="center" spacing={2}>
      {loading && (
        <FingoCircularProgressLoader />
      )}
      {showError && (
        <>
          <Typography variant="h3" color="primary">
            ¡Tu sesión en acepta ha expirado!
          </Typography>
          <Typography variant="h5">
            Intente refrescar su sesión en Acepta e intente nuevamente, o aprete <Link to="/login?acepta=true">aquí</Link> para hacer login con sus credenciales de Acepta
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default AceptaClickController;
